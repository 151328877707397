import React from 'react';
import { Link } from 'react-router-dom';

export default function Header() {
  return (
    <div className='mx-auto max-w-2xl text-center'>
      <Link to='/about'>
        <img
          alt=''
          src='./tejal-dp.jpg'
          className='h-20 w-20 rounded-full bg-gray-50 mx-auto'
        />
        <h2 className='mt-2 text-base font-semibold leading-7 text-gray-900'>
          Tejal Vyas
        </h2>
      </Link>
      <p className='mt-2 pd line-clamp-3 text-md leading-6 text-gray-600'>
        Hi, welcome to my blog. Here I share insights on talent management,
        leadership, and the evolving world of HR. Thanks for stopping by—I hope
        you find something that inspires or helps you on your journey!
      </p>
    </div>
  );
}
