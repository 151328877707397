import matter from 'gray-matter';
import prettyDate from '../utils/date';
import slugify from '../utils/slugify';

// Use raw-loader & require.context to get all .md files
const context = require.context('!!raw-loader!../data/blog', false, /\.md$/);

// Use gray-matter to parse .md file data
const parsePost = (fileContent, fileName) => {
  const { data, content } = matter(fileContent);
  return {
    ...data,
    content,
    createdAt: prettyDate(data.createdAt),
    slug: fileName.replace(/^\.\//, '').replace(/\.md$/, ''), // Extract slug from filename
  };
};

// Find all unique categories
const findAllCategories = (posts) => {
  const categoryNames = posts.map((post) => post.categories);
  return [...new Set(categoryNames.flat())];
};

// Find all posts for a category with count
const findAllCategoryPosts = (posts, category) => {
  const result = {
    name: category,
    slug: slugify(category),
    postCount: 0,
    posts: [],
  };

  posts.forEach((post) => {
    if (post.categories.includes(category)) {
      result.postCount += 1;
      result.posts.push(post);
    }
  });

  return result;
};

// Process & parse all .md files & sort them by date
export const posts = context
  .keys()
  .map((fileName) => {
    const fileContent = context(fileName);
    return parsePost(fileContent.default || fileContent, fileName);
  })
  .sort((a, b) => b.createdAt.localeCompare(a.createdAt));

// Find all categories with post counts
export const categories = findAllCategories(posts).map((category) =>
  findAllCategoryPosts(posts, category)
);
