import React from 'react';

const stats = [
  { label: 'Trainings Conducted', value: '150' },
  { label: 'Employees Served', value: '18k' },
  { label: 'Countries Served', value: '5' },
  { label: 'DEI Initiatives', value: '34' },
];

export default function About() {
  return (
    <section className='shadow-inner'>
      <div className='mx-auto max-w-screen-xl px-4 py-16 sm:px-6 sm:py-12 lg:px-8 lg:py-16'>
        <div className='grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16' />
        <div className='bg-white px-6 py-32 lg:px-8'>
          <div className='mx-auto max-w-3xl text-base leading-7 text-gray-700'>
            <p className='text-base font-semibold leading-7 text-blue-600'>
              Dear Reader,
            </p>
            <p className='mt-6 text-xl leading-8'>
              Welcome to my digital space, where I am delighted to share not
              just my professional journey but the experiences that have shaped
              who I am today. My name is Tejal Vyas, and my career in Human
              Resources has been a journey of connection, growth, and commitment
              to making a positive impact on the lives of those I work with.
            </p>
            <p className='mt-6 text-xl leading-8'>
              Over the past decade, I’ve had the privilege of working in various
              roles within HR, each one offering me the opportunity to
              contribute to people development, diversity, and organizational
              growth. My journey began as a trainee, where I quickly learned
              that the heart of any organization is its people. This
              understanding has driven me to lead initiatives that foster
              inclusive environments, create meaningful development programs,
              and support others in realizing their potential.
            </p>
            <p className='mt-6 text-xl leading-8'>
              At Tata Technologies, I’ve been entrusted with leading diversity,
              equity, and inclusion (DEI) initiatives across geographies. This
              role has allowed me to not only support the organization’s
              strategic goals but also to advocate for underrepresented groups,
              ensuring everyone has a voice and a sense of belonging. Similarly,
              at Skoda Auto India, I steered multiple aspects of the HR
              department, where I developed and implemented competency
              frameworks, leadership programs, and employee engagement
              strategies that significantly improved retention and morale.
            </p>
            <figure className='mt-10 border-l border-blue-600 pl-9'>
              <blockquote className='font-semibold text-gray-900'>
                <p>
                  “Tejal to me is a bright, intelligent young talent. She is
                  goal-oriented and a natural networker. Has the presence of
                  mind to look at a problem from various angles and is an asset
                  to the team.”
                </p>
              </blockquote>
              <figcaption className='mt-6 flex gap-x-4'>
                <img
                  alt=''
                  src='./testimonials/nisha.jpg'
                  className='h-6 w-6 flex-none rounded-full bg-gray-50'
                />
                <div className='text-sm leading-6'>
                  <strong className='font-semibold text-gray-900'>
                    Nisha Karkera
                  </strong>{' '}
                  – Head of Talent & Training @Škoda India
                </div>
              </figcaption>
            </figure>
            <p className='mt-6 text-xl leading-8'>
              Every role I’ve undertaken has been about more than just achieving
              targets—it’s been about touching lives, building strong
              relationships, and creating environments where people can thrive.
              Whether it was reducing attrition rates, increasing diversity, or
              developing high-potential leaders, my focus has always been on
              driving positive change through thoughtful and strategic HR
              practices.
            </p>
            <figure className='mt-10 border-l border-blue-600 pl-9'>
              <blockquote className='font-semibold text-gray-900'>
                <p>
                  “Tejal is a young, talented, enthusiastic person. She is
                  visible and builds her presence in the group. A strong
                  presence of mind and her intelligence supports her to
                  accomplish her organizational goals. She can be a good
                  resource to any organization wherever recommended.”
                </p>
              </blockquote>
              <figcaption className='mt-6 flex gap-x-4'>
                <img
                  alt=''
                  src='./testimonials/amit.jpg'
                  className='h-6 w-6 flex-none rounded-full bg-gray-50'
                />
                <div className='text-sm leading-6'>
                  <strong className='font-semibold text-gray-900'>
                    Amit Arora
                  </strong>{' '}
                  – AGM @Škoda India
                </div>
              </figcaption>
            </figure>
            <p className='mt-6 text-xl leading-8'>
              My work has been recognized on numerous occasions, but what truly
              matters to me is the difference I’ve been able to make in the
              lives of the employees and teams I’ve worked with. I believe that
              by supporting others in their growth, we create a ripple effect
              that benefits the entire organization.
            </p>

            <p className='mt-6 text-xl leading-8'>
              As you explore my website, I hope you’ll see not just the
              milestones of my career but the dedication and care that I bring
              to everything I do. My journey is one of continuous learning and
              growth, and I am excited about the future opportunities to make a
              difference.
            </p>
            <p className='mt-6 text-xl leading-8'>
              Thank you for taking the time to get to know me. I look forward to
              the possibility of connecting with you and exploring how we can
              create something meaningful together.
            </p>
            <p className='mt-6 text-xl leading-8'>
              Warm regards,
              <br />
              <span className='text-blue-600'>Tejal Vyas</span>
            </p>
            <dl className='mt-10 grid grid-cols-2 gap-8 border-t border-gray-900/10 pt-10 sm:grid-cols-4'>
              {stats.map((stat) => (
                <div key={stat.label}>
                  <dt className='text-sm font-semibold leading-6 text-gray-600'>
                    {stat.label}
                  </dt>
                  <dd className='mt-2 text-3xl font-bold leading-10 tracking-tight text-gray-900'>
                    {stat.value}
                  </dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
      </div>
    </section>
  );
}
