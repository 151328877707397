import React from 'react';
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from '@headlessui/react';
import { Link } from 'react-router-dom';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';

export default function Header() {
  return (
    <Disclosure as='nav' className='bg-white shadow-xl'>
      <div className='mx-auto max-w-7xl px-4 sm:px-6 lg:px-8'>
        <div className='flex h-16 justify-between'>
          <div className='flex basis-full'>
            <div className='flex flex-shrink-0 items-center'>
              <Link to='/'>
                <img
                  alt='Your Company'
                  src='./main-logo.svg'
                  className='h-8 w-auto'
                />
              </Link>
            </div>
            <div className='flex basis-full flex-row-reverse'>
              <div className='hidden lg:flex lg:gap-x-12'>
                <Link
                  to='/'
                  className='inline-flex items-center border-b-2 border-transparent hover:border-teal-500 px-1 pt-1 text-sm font-medium text-gray-500 hover:text-gray-900'
                >
                  Home
                </Link>
                <Link
                  to='/blog'
                  className='inline-flex items-center border-b-2 border-transparent px-1 pt-1 text-sm font-medium text-gray-500 hover:border-teal-500 hover:text-gray-700'
                >
                  Blog
                </Link>
                <Link
                  to='/about'
                  className='inline-flex items-center border-b-2 border-transparent px-1 pt-1 text-sm font-medium text-gray-500 hover:border-teal-500 hover:text-gray-700'
                >
                  About
                </Link>
                <Link
                  to='/contact'
                  className='inline-flex items-center border-b-2 border-transparent px-1 pt-1 text-sm font-medium text-gray-500 hover:border-teal-500 hover:text-gray-700'
                >
                  Contact
                </Link>
              </div>
            </div>
          </div>
          <div className='-mr-2 flex items-center sm:hidden'>
            {/* Mobile menu button */}
            <DisclosureButton className='group relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-teal-500'>
              <span className='absolute -inset-0.5' />
              <span className='sr-only'>Open main menu</span>
              <Bars3Icon
                aria-hidden='true'
                className='block h-6 w-6 group-data-[open]:hidden'
              />
              <XMarkIcon
                aria-hidden='true'
                className='hidden h-6 w-6 group-data-[open]:block'
              />
            </DisclosureButton>
          </div>
        </div>
      </div>

      <DisclosurePanel className='sm:hidden'>
        {({ close }) => (
          <div className='space-y-1 pb-3 pt-2'>
            <DisclosureButton
              as='div'
              to='/'
              className='block border-l-4 text-gray-500 hover:border-teal-500 hover:bg-teal-50 py-2 pl-3 pr-4 text-base font-medium text-grey-500'
            >
              <Link to='/' className='w-full block' onClick={() => close()}>
                Home
              </Link>
            </DisclosureButton>
            <DisclosureButton
              as='div'
              to='/'
              className='block border-l-4 border-transparent text-gray-500 hover:bg-teal-50 hover:border-teal-500 hover:text-teal-700 py-2 pl-3 pr-4 text-base font-medium'
            >
              <Link to='/blog' className='w-full block' onClick={() => close()}>
                Blog
              </Link>
            </DisclosureButton>
            <DisclosureButton
              as='div'
              to='/'
              className='block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-gray-500 hover:bg-teal-50 hover:border-teal-500 hover:text-teal-700'
            >
              <Link
                to='/about'
                className='w-full block'
                onClick={() => close()}
              >
                About
              </Link>
            </DisclosureButton>
            <DisclosureButton
              as='div'
              className='block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-gray-500 hover:bg-teal-50 hover:border-teal-500 hover:text-teal-700'
            >
              <Link
                to='/contact'
                className='w-full block'
                onClick={() => close()}
              >
                Contact
              </Link>
            </DisclosureButton>
          </div>
        )}
      </DisclosurePanel>
    </Disclosure>
  );
}
