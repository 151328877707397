import React from 'react';
import {
  BuildingOffice2Icon,
  EnvelopeIcon,
  PhoneIcon,
} from '@heroicons/react/24/outline';

export default function Contact() {
  return (
    <section className='shadow-inner'>
      <div className='mx-auto max-w-screen-xl px-4 py-16 sm:px-6 sm:py-12 lg:px-8 lg:py-16'>
        <div className='grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16' />
        <div className='relative isolate bg-white'>
          <div className='mx-auto grid max-w-7xl grid-cols-1 lg:grid-cols-2'>
            <div className='relative px-6 pb-20 pt-24 sm:pt-32 lg:static lg:px-8 lg:py-48'>
              <div className='mx-auto max-w-xl lg:mx-0 lg:max-w-lg'>
                <div className='absolute inset-y-0 left-0 -z-10 w-full overflow-hidden bg-gray-100 ring-1 ring-gray-900/10 lg:w-1/2'>
                  <svg
                    aria-hidden='true'
                    className='absolute inset-0 h-full w-full stroke-gray-200 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]'
                  >
                    <defs>
                      <pattern
                        x='100%'
                        y={-1}
                        id='83fd4e5a-9d52-42fc-97b6-718e5d7ee527'
                        width={200}
                        height={200}
                        patternUnits='userSpaceOnUse'
                      >
                        <path d='M130 200V.5M.5 .5H200' fill='none' />
                      </pattern>
                    </defs>
                    <rect
                      fill='white'
                      width='100%'
                      height='100%'
                      strokeWidth={0}
                    />
                    <svg
                      x='100%'
                      y={-1}
                      className='overflow-visible fill-gray-50'
                    >
                      <path d='M-470.5 0h201v201h-201Z' strokeWidth={0} />
                    </svg>
                    <rect
                      fill='url(#83fd4e5a-9d52-42fc-97b6-718e5d7ee527)'
                      width='100%'
                      height='100%'
                      strokeWidth={0}
                    />
                  </svg>
                </div>
                <h2 className='text-3xl font-bold tracking-tight text-gray-900'>
                  Let’s connect
                </h2>
                <p className='mt-6 text-lg leading-8 text-gray-600'>
                  Whether you’re looking to collaborate or just want to chat
                  about the latest in HR, I’d love to hear from you.
                </p>
                <dl className='mt-10 space-y-4 text-base leading-7 text-gray-600'>
                  <div className='flex gap-x-4'>
                    <dt className='flex-none'>
                      <span className='sr-only'>Address</span>
                      <BuildingOffice2Icon
                        aria-hidden='true'
                        className='h-7 w-6 text-gray-400'
                      />
                    </dt>
                    <dd>Pune, India</dd>
                  </div>
                  <div className='flex gap-x-4'>
                    <dt className='flex-none'>
                      <span className='sr-only'>Telephone</span>
                      <PhoneIcon
                        aria-hidden='true'
                        className='h-7 w-6 text-gray-400'
                      />
                    </dt>
                    <dd>
                      <a
                        href='https://calendly.com/tejalvyas/30min'
                        className='hover:text-blue-600'
                        target='_blank'
                        rel='noreferrer'
                      >
                        calendly.com/tejalvyas
                      </a>
                    </dd>
                  </div>
                  <div className='flex gap-x-4'>
                    <dt className='flex-none'>
                      <span className='sr-only'>Email</span>
                      <EnvelopeIcon
                        aria-hidden='true'
                        className='h-7 w-6 text-gray-400'
                      />
                    </dt>
                    <dd>
                      <a
                        href='mailto:tejalvyas.hr@gmail.com'
                        className='hover:text-blue-600'
                      >
                        tejalvyas.hr@gmail.com
                      </a>
                    </dd>
                  </div>
                </dl>
              </div>
            </div>
            <form
              action='#'
              method='POST'
              className='px-6 pb-24 pt-20 sm:pb-32 lg:px-8 lg:py-48 border border-spacing-3 border-gray-200'
            >
              <div className='mx-auto max-w-xl lg:mr-0 lg:max-w-lg'>
                <div className='grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2'>
                  <div>
                    <label
                      htmlFor='first-name'
                      className='block text-sm font-semibold leading-6 text-gray-900'
                    >
                      First name
                    </label>
                    <div className='mt-2.5'>
                      <input
                        id='first-name'
                        name='first-name'
                        type='text'
                        autoComplete='given-name'
                        className='block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6'
                      />
                    </div>
                  </div>
                  <div>
                    <label
                      htmlFor='last-name'
                      className='block text-sm font-semibold leading-6 text-gray-900'
                    >
                      Last name
                    </label>
                    <div className='mt-2.5'>
                      <input
                        id='last-name'
                        name='last-name'
                        type='text'
                        autoComplete='family-name'
                        className='block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6'
                      />
                    </div>
                  </div>
                  <div className='sm:col-span-2'>
                    <label
                      htmlFor='email'
                      className='block text-sm font-semibold leading-6 text-gray-900'
                    >
                      Email
                    </label>
                    <div className='mt-2.5'>
                      <input
                        id='email'
                        name='email'
                        type='email'
                        autoComplete='email'
                        className='block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6'
                      />
                    </div>
                  </div>
                  <div className='sm:col-span-2'>
                    <label
                      htmlFor='phone-number'
                      className='block text-sm font-semibold leading-6 text-gray-900'
                    >
                      Phone number
                    </label>
                    <div className='mt-2.5'>
                      <input
                        id='phone-number'
                        name='phone-number'
                        type='tel'
                        autoComplete='tel'
                        className='block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6'
                      />
                    </div>
                  </div>
                  <div className='sm:col-span-2'>
                    <label
                      htmlFor='message'
                      className='block text-sm font-semibold leading-6 text-gray-900'
                    >
                      Message
                    </label>
                    <div className='mt-2.5'>
                      <textarea
                        id='message'
                        name='message'
                        rows={4}
                        className='block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6'
                        defaultValue=''
                      />
                    </div>
                  </div>
                </div>
                <div className='mt-8 flex justify-end'>
                  <button
                    type='submit'
                    className='mt-4 group relative inline-block text-sm font-medium text-green-600 focus:outline-none focus:ring active:text-green-500'
                  >
                    <span className='absolute inset-0 translate-x-0.5 translate-y-0.5 bg-green-600 transition-transform group-hover:translate-x-0 group-hover:translate-y-0' />
                    <span className='relative block border border-current bg-white px-8 py-3'>
                      Send Message
                    </span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}
