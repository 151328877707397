import React from 'react';
import { Link } from 'react-router-dom';

export default function Links() {
  return (
    <div className='mt-12 space-y-12 border-t border-gray-200 pt-12'>
      <div className='mx-auto max-w-2xl text-left'>
        <Link to='/blog/all' className='text-blue-500 hover:underline'>
          View All Blogs
        </Link>
        <span className='mx-2'>|</span>
        <Link to='/blog/categories' className='text-blue-500 hover:underline'>
          View All Categories
        </Link>
      </div>
    </div>
  );
}
