import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import slugify from '../../utils/slugify';

export default function List({ posts }) {
  return (
    <>
      {posts.map((post) => (
        <div
          className='mt-12 space-y-12 border-t border-gray-200 pt-12'
          key={post.slug}
        >
          <article className='flex flex-col items-start justify-between'>
            <div className='flex items-center gap-x-4 text-xs'>
              <time dateTime={post.createdAt} className='text-gray-500'>
                {post.createdAt}
              </time>
              {post.categories.map((category) => (
                <Link
                  to={`/blog/categories/${slugify(category)}`}
                  className='relative z-10 rounded-full bg-gray-50 px-3 py-1.5
            font-medium text-gray-600 hover:bg-gray-200'
                >
                  {category}
                </Link>
              ))}
            </div>
            <div className='group relative'>
              <h3 className='mt-3 text-xl font-semibold leading-6 text-gray-900 group-hover:text-gray-600'>
                <Link
                  to={`/blog/${post.slug}`}
                  className='group-hover:text-blue-500'
                >
                  <span className='absolute inset-0' />
                  {post.title}
                </Link>
              </h3>
              <p className='mt-2 line-clamp-3 text-md leading-6 text-gray-600'>
                {post.description}
              </p>
            </div>
          </article>
        </div>
      ))}
    </>
  );
}

List.propTypes = {
  posts: PropTypes.arrayOf(PropTypes.object).isRequired,
};
