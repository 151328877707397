import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import List from './List';

export default function All({ posts }) {
  const [postsToShow, setPostsToShow] = useState(10);

  const loadMorePosts = () => {
    setPostsToShow((prevValue) => prevValue + 10);
  };

  return (
    <section className='shadow-inner'>
      <div className='mx-auto max-w-screen-xl px-4 py-16 sm:px-6 sm:py-12 lg:px-8 lg:py-16'>
        <div className='grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16' />
        <div className='bg-white py-24 lg:px-8'>
          <div className='mx-auto max-w-7xl px-6 lg:px-8'>
            <div className='mx-auto max-w-2xl'>
              <div className='flex items-center justify-between'>
                <div className='min-w-0 flex-1'>
                  <h2 className='text-3xl font-bold text-gray-900 sm:text-4xl tracking-tight'>
                    All Blogs
                  </h2>
                </div>
                <div className='flex ml-4 mt-0'>
                  <Link to='/blog/categories'>
                    <button
                      type='button'
                      className='inline-flex items-center rounded-full bg-blue-600 px-4 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600'
                    >
                      View All Categories
                    </button>
                  </Link>
                </div>
              </div>
              <p className='mt-2 text-lg leading-8 text-gray-600'>
                I share insights on talent management, leadership, and the
                evolving world of HR.
              </p>
              <List posts={posts.slice(0, postsToShow)} />
              <div className='mt-8 mx-auto justify-center text-center'>
                {postsToShow < posts.length && (
                  <button
                    onClick={loadMorePosts}
                    type='button'
                    className='rounded-full bg-white px-4 py-2.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50'
                  >
                    Load more
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

All.propTypes = {
  posts: PropTypes.arrayOf(PropTypes.object).isRequired,
};
