import React from 'react';

const testimonials = [
  {
    body: 'Working & interaction with Tejal was extremely hopeful and productive. She exceeds in making people comfortable with conversations and her ability to listen and understand core issues helps in deriving tangible solutions. Her apt and crisp networking skills help in defining clear direction to what exactly needs to be done, very important for talent management. Her ability to get back on time with proper feedback leads to faster decision making. Her calm and patient nature is top tier. She is very balanced on her interpersonal skills. Tejal is a valued and productive asset for any organization.',
    author: {
      name: 'Deepika Utekar',
      company: 'Škoda India',
      imageUrl: './testimonials/deepika.jpg',
      title: 'Deputy Manager',
    },
  },
  {
    body: 'Having worked with Tejal for two years, I have to say, she is an excellent professional. I am impressed with her work ethic and communication skills. I deeply appreciate her efforts in showing relentless effort for various projects that you handled. All the best with future endeavours.',
    author: {
      name: 'Pooja Iyer',
      company: 'Škoda India',
      imageUrl: './testimonials/pooja.jpg',
      title: 'Training Admin',
    },
  },
  {
    body: 'It was always great working with Tejal. If she is leading a project, you need not think about meeting deadlines. She will make the team work. I had the opportunity to work with her in a CFT. She comes with enriched knowledge on Talent Mgmt and also has driven DEI projects where she took a lead role. She will always be an asset to any organisation she partners with. Wishing all the best…',
    author: {
      name: 'Pourush Chaudhury',
      company: 'Škoda India',
      imageUrl: './testimonials/pourush.jpg',
      title: 'HRBP',
    },
  },
  {
    body: 'Versatile, hard-working, genuine and honest are few of the many attributes that define Tejal. Always ready to help and support through any HR related queries and a never give up attitude. Was a pleasure knowing and working with you at Škoda Auto Volkswagen India Private Limited and surely you will be an asset wherever you go.',
    author: {
      name: 'Robinson S.',
      company: 'Škoda India',
      imageUrl: './testimonials/robinson.jpg',
      title: 'Process Engineer',
    },
  },
  {
    body: 'Ms. Tejal Vyas, is a highly experienced HR professional with a strong background in training, recruitment, diversity and inclusion. Having had the pleasure of working closely with Tejal at Skoda Volkswagen India Pvt. Ltd., I can confidently say that she is an invaluable asset in the field of human resources...',
    author: {
      name: 'Tanmay Game',
      company: 'Škoda India',
      imageUrl: './testimonials/tanmay.jpg',
      title: 'Industrial Engineer',
    },
  },
  {
    body: 'Tejal is a young, talented, enthusiastic person. She is visible and builds her presence in the group. A strong presence of mind and her intelligence supports her to accomplish her organizational goals. She can be a good resource to any organization wherever recommended.',
    author: {
      name: 'Amit Arora',
      company: 'Škoda India',
      imageUrl: './testimonials/amit.jpg',
      title: 'AGM',
    },
  },
  {
    body: 'Tejal to me is a bright, intelligent young talent. She is goal-oriented and a natural networker. Has the presence of mind to look at a problem from various angles and is an asset to the team.',
    author: {
      name: 'Nisha Karkera',
      company: 'Škoda India',
      imageUrl: './testimonials/nisha.jpg',
      title: 'Head of Talent & Training',
    },
  },
  {
    body: 'Tejal is a perfect example of a skilled HR manager, and she brings all the abilities to the table. She is focused, reliable, and goal-oriented. She is kind and an outstanding person and knows how to bring the best out of each person.',
    author: {
      name: 'Varsha More',
      company: 'Škoda India',
      imageUrl: './testimonials/varsha.jpg',
      title: 'HRBP',
    },
  },
  {
    body: 'I have known Tejal since our school days and have seen how committed she is towards the goals that she wants to achieve for herself and the team. Her reach out to keep her network posted about the latest developments and talent acquisition programs in her organization, especially through social media where she is working as HR is admirable. She actively maintains good relationships within and outside of work and is one of the highlight traits of hers. She would definitely be a valuable asset to any team and organization she joins or works for.',
    author: {
      name: 'Sachin Zinjurde',
      company: 'Infosys',
      imageUrl: './testimonials/sachin.jpg',
      title: 'Technology Lead',
    },
  },
  {
    body: 'Tejal is a very hard-working and sincere employee. She has perfection in every work she does!',
    author: {
      name: 'Rishi Sarda',
      company: 'Godrej Infotech',
      imageUrl: './testimonials/user.jpg',
      title: 'Manager',
    },
  },
  {
    body: 'Extremely dedicated & hard working. Her enthusiasm for the job comes through in all she does. She has a positive attitude & very good communication skills that makes her a pleasure to work with & is one of the reasons she is so well liked by her co-workers. I highly recommended Tejal for any job she is considering in the HR field.',
    author: {
      name: 'Prathamesh Patil',
      company: 'Prince Pipes',
      imageUrl: './testimonials/prathamesh.jpg',
      title: 'Sales & Strategy Planner',
    },
  },
  {
    body: 'I had the privilege to work with Tejal for 2+ years. She has excellent People orientation, Constructive Thinking, Analytical & Management Skills and is an Enthusiastic co-worker with great Team bonding spirit.',
    author: {
      name: 'Pratiksingh Jadhav',
      company: 'Škoda India',
      imageUrl: './testimonials/user.jpg',
      title: 'HR Officer',
    },
  },
  {
    body: 'Ms. Tejal Vyas is a wonderful colleague to work with, and has exceptional expertise in data analysis. Handling multiple projects was unlike any I have seen before. I had the pleasure of working with her for one year. Her exceptional workflow, skills, ingenuity, and insightful enthusiasm towards her responsibility and duties has won many recommendations. Even in all tense situations, she always carries a smile. As my team member, she earns the highest recommendations from me.',
    author: {
      name: 'Mukesh Singh',
      company: 'Sanjeev Auto',
      imageUrl: './testimonials/mukesh.jpg',
      title: 'HR Team Lead',
    },
  },
  {
    body: 'Ms. Tejal is a motivated, forward-thinking, and also intelligent personality who has lots of knowledge in her domain. It was a fantastic experience to work together with her. She works with full enthusiasm and dedication. I wish her all the best for a bright future :)',
    author: {
      name: 'Ganesh Dhurat',
      company: 'Škoda India',
      imageUrl: './testimonials/user.jpg',
      title: 'Manager-IT',
    },
  },
  {
    body: 'Dedication, leadership, hardwork and enthusiast are some key words I would like to use for Tejal. She not only manages her tasks beautifully but then she sets an altogether high standards for others to match up to. Always keen to learn new things and always tries to experiment things to gain knowledge out of it. She is professional but at the same time will be available for someone in need without even thinking about her loss. A true companion and a adorable colleague to work with. ',
    author: {
      name: 'Meetali Vakir Dhurat',
      company: 'Škoda India',
      imageUrl: './testimonials/meetali.jpg',
      title: 'Compliance Specialist',
    },
  },
  {
    body: 'Tejal has always had a dedicated and confident persona for the commitments she takes up. Apart from delivering with excellence and precision, she has the power to enunciate what has to be conveyed, clearly and efficiently, while making sure that the situation does not go out of hands in delicate situations as well. An asset for success.',
    author: {
      name: 'Tarun Kochar',
      company: 'Accenture',
      imageUrl: './testimonials/tarun.jpg',
      title: 'Consultant',
    },
  },
];

export default function Testimonials() {
  return (
    <div className='bg-white py-16 sm:py-16'>
      <div className='mx-auto max-w-7xl px-6 lg:px-8'>
        <div className='mx-auto max-w-xl text-center'>
          <p className='mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl'>
            Voices of My Journey
          </p>
        </div>
        <div className='mx-auto mt-16 flow-root max-w-2xl sm:mt-20 lg:mx-0 lg:max-w-none'>
          <div className='-mt-8 sm:-mx-4 sm:columns-2 sm:text-[0] lg:columns-3'>
            {testimonials.map((testimonial) => (
              <div
                key={testimonial.author.name}
                className='pt-8 sm:inline-block sm:w-full sm:px-4'
              >
                <figure className='rounded-2xl bg-gray-50 p-8 text-sm leading-6'>
                  <blockquote className='text-gray-900'>
                    <p>{`“${testimonial.body}”`}</p>
                  </blockquote>
                  <figcaption className='mt-6 flex items-center gap-x-4'>
                    <img
                      alt=''
                      src={testimonial.author.imageUrl}
                      className='h-10 w-10 rounded-full bg-gray-50'
                    />
                    <div>
                      <div className='font-semibold text-gray-900'>
                        {testimonial.author.name}
                      </div>
                      <div className='text-gray-600'>
                        {`${testimonial.author.title}`}
                        {` @${testimonial.author.company}`}
                      </div>
                    </div>
                  </figcaption>
                </figure>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
