import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

export default function Categories({ categories }) {
  return (
    <section className='shadow-inner'>
      <div className='mx-auto max-w-screen-xl px-4 py-16 sm:px-6 sm:py-12 lg:px-8 lg:py-16'>
        <div className='grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16' />
        <div className='bg-white py-24 lg:px-8'>
          <div className='mx-auto max-w-7xl px-6 lg:px-8'>
            <div className='mx-auto max-w-2xl'>
              <div className='flex items-center justify-between'>
                <div className='min-w-0 flex-1'>
                  <h2 className='text-3xl font-bold text-gray-900 sm:text-4xl tracking-tight'>
                    Categories
                  </h2>
                </div>
                <div className='flex ml-4 mt-0'>
                  <Link to='/blog/all'>
                    <button
                      type='button'
                      className='inline-flex items-center rounded-full bg-blue-600 px-4 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600'
                    >
                      View All Blogs
                    </button>
                  </Link>
                </div>
              </div>
              <p className='mt-2 text-lg leading-8 text-gray-600'>
                I share insights on talent management, leadership, and the
                evolving world of HR.
              </p>
              <div className='mt-12 space-y-12 border-t border-gray-200 pt-12'>
                <ul className='mt-3 grid grid-cols-2 gap-5 sm:grid-cols-3 sm:gap-4 lg:grid-cols-4'>
                  {categories.map((category) => (
                    <Link to={`/blog/categories/${category.slug}`}>
                      <li
                        key={category.name}
                        className='col-span-1 flex rounded-md shadow-sm'
                      >
                        <div className='flex flex-1 items-center justify-between truncate rounded border border-gray-200 bg-blue-100'>
                          <div className='flex-1 px-4 py-2 text-sm'>
                            <p className='font-medium text-gray-900 hover:text-gray-600'>
                              {category.name}
                            </p>
                            <p className='text-gray-500'>
                              {category.postCount} Blog
                              {category.postCount > 1 ? 's' : ''}
                            </p>
                          </div>
                        </div>
                      </li>
                    </Link>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

Categories.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.object).isRequired,
};
