import React from 'react';
import { Link } from 'react-router-dom';

export default function ErrorPage() {
  return (
    <section className='shadow-inner'>
      <div className='mx-auto max-w-screen-xl px-4 py-16 sm:px-6 sm:py-12 lg:px-8 lg:py-16'>
        <div className='grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16' />
        <div className='relative isolate bg-white'>
          <main className='grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8'>
            <div className='text-center'>
              <p className='text-base font-semibold text-blue-600'>404</p>
              <h1 className='mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl'>
                Page Not Found
              </h1>
              <p className='mt-6 text-base leading-7 text-gray-600'>
                Sorry, we couldn’t find the page you’re looking for.
              </p>
              <div className='mt-10 flex items-center justify-center gap-x-6'>
                <Link
                  to='/'
                  className='rounded-md bg-blue-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600'
                >
                  Go back home
                </Link>
                <Link
                  to='/contact'
                  className='text-sm border px-3.5 py-2.5 rounded font-semibold text-gray-900'
                >
                  Contact support <span aria-hidden='true'>&rarr;</span>
                </Link>
              </div>
            </div>
          </main>
        </div>
      </div>
    </section>
  );
}
