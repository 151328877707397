import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Markdown from './Markdown';

export default function Post({ posts }) {
  const { slug } = useParams();
  const [post, setPost] = useState(null);

  useEffect(() => {
    setPost(posts.find((p) => p.slug === slug));
  }, []);

  if (!post) {
    return <div>Post not found</div>;
  }

  return (
    <section className='shadow-inner'>
      <div className='mx-auto max-w-screen-xl px-4 py-16 sm:px-6 sm:py-12 lg:px-8 lg:py-16'>
        <div className='grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16' />
        <div className='bg-white px-6 py-32 lg:px-8'>
          <div className='mx-auto max-w-3xl text-base leading-7 text-gray-700'>
            <h2 className='text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl'>
              {post.title}
            </h2>
            <div className='relative mt-4 flex items-center gap-x-4'>
              <img
                alt=''
                src={post.author.imageUrl}
                className='h-10 w-10 rounded-full bg-gray-50'
              />
              <div className='text-sm leading-6'>
                <p className='font-semibold text-gray-900'>
                  <Link to='/about'>
                    <span className='absolute inset-0' />
                    Written By {post.author.name}
                  </Link>
                </p>
                <p className='text-gray-600'>{post.createdAt}</p>
              </div>
            </div>
            <div className='mt-10 space-y-16 border-t border-gray-200 pt-10'>
              <Markdown content={post.content} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

Post.propTypes = {
  posts: PropTypes.arrayOf(PropTypes.object).isRequired,
};
