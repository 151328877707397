import React from 'react';
import PropTypes from 'prop-types';
import List from '../components/blog/List';
import Header from '../components/blog/Header';
import Links from '../components/blog/Links';

export default function Blog({ posts }) {
  return (
    <section className='shadow-inner'>
      <div className='mx-auto max-w-screen-xl px-4 py-16 sm:px-6 sm:py-12 lg:px-8 lg:py-16'>
        <div className='grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16' />
        <div className='bg-white py-24 lg:px-8'>
          <div className='mx-auto max-w-7xl px-6 lg:px-8'>
            <div className='mx-auto max-w-2xl'>
              <Header />
              <List posts={posts} />
              <Links />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

Blog.propTypes = {
  posts: PropTypes.arrayOf(PropTypes.object).isRequired,
};
