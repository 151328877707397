import React from 'react';
import './App.css';
import {
  createBrowserRouter,
  RouterProvider,
  Route,
  createRoutesFromElements,
} from 'react-router-dom';
import PlausibleProvider from './layouts/PlausibleProvider';
import Layout from './layouts/Layout';
import Home from './pages/Home';
import Blog from './pages/Blog';
import Contact from './pages/Contact';
import About from './pages/About';
import Post from './components/blog/Post';
import All from './components/blog/All';
import Categories from './components/blog/Categories';
import Category from './components/blog/Category';
import ErrorPage from './pages/ErrorPage';
import { posts, categories } from './api/blog';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<PlausibleProvider />}>
      <Route path='/' element={<Layout />}>
        <Route index element={<Home />} />
        <Route path='blog' element={<Blog posts={posts.slice(0, 9)} />} />
        <Route path='blog/all' element={<All posts={posts} />} />
        <Route
          path='blog/categories'
          element={<Categories categories={categories} />}
        />
        <Route path='blog/:slug' element={<Post posts={posts} />} />
        <Route
          path='blog/categories/:slug'
          element={<Category categories={categories} />}
        />
        <Route path='contact' element={<Contact />} />
        <Route path='about' element={<About />} />
        {/* Fallback route */}
        <Route path='*' element={<ErrorPage />} />
      </Route>
    </Route>
  )
);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
